@font-face {
  font-family: Lato;
  src: url("Lato-Regular.ad872c70.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Italic.37a74cce.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Bold.f53d8879.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url("Lato-BoldItalic.ce6dea93.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Material Symbols Outlined;
  font-style: normal;
  font-weight: 100 700;
  font-display: block;
  src: url("material-symbols-outlined.ba380871.woff2") format("woff2");
}

.material-symbols-outlined {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-family: Material Symbols Outlined;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

@font-face {
  font-family: Material Symbols Rounded;
  font-style: normal;
  font-weight: 100 700;
  font-display: block;
  src: url("material-symbols-rounded.4e1f324d.woff2") format("woff2");
}

.material-symbols-rounded {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-family: Material Symbols Rounded;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

@font-face {
  font-family: Material Symbols Sharp;
  font-style: normal;
  font-weight: 100 700;
  font-display: block;
  src: url("material-symbols-sharp.5070c351.woff2") format("woff2");
}

.material-symbols-sharp {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-family: Material Symbols Sharp;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

/*# sourceMappingURL=index.b1b692e6.css.map */
