@font-face {
	font-family: 'Lato';
	src: url('Lato-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('Lato-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('Lato-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('Lato-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
